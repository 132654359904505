
import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, {  ModalGateway } from "react-images";
import { photos ,imageList} from "./Photos";
import { Col, Container, Image, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ImageSlider from './ImageSlider'
export default function App() {
  const [currentImage, setCurrentImage] = useState([]);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [listTitle, setListTitle]=useState();

  // const openLightbox = useCallback((event, { photo, index }) => {
  //   setCurrentImage(index);
  //   setViewerIsOpen(true);
  // }, []);

  // const closeLightbox = () => {
  //   setCurrentImage(0);
  //   setViewerIsOpen(false);
  // };
  const showGaleryModal=(item,itemTitle)=>{
    console.log(item,"00000000000")
    setViewerIsOpen(true);
    setCurrentImage(item);
    setListTitle(itemTitle)
  }
  const handleClose=()=>{
    setViewerIsOpen(false);
  }

  return (
    <>
   <section className="workflow"> 
   <Container>
    <h4 className="title mt-5 pt-3 mb-3">HONEYCOMB GALLERY</h4>
   
      <Row className="mt-2">
        {
          imageList?.map((item)=>{
              return(
                <Col lg={4} md={4} sm={4} xs={12}  className="galeryBox mb-5 mt-3">
                  <div style={{position:'relative'}}>
                      <Image src={item?.imgUrl} className="galeryItem" onClick={()=>showGaleryModal(item?.imageGalery, item?.title)} width="100%" />
                      <div className="imgCaption">{item?.title}</div>
                   </div>
               </Col>
              )
          })
        }
      </Row>
      <Modal show={viewerIsOpen} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{listTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageSlider data={currentImage}/>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
      </Container>
    {/* <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div> */}
  
   </section>
    </>
  );
}
