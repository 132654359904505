import React from "react";
import {Carousel, Image} from "react-bootstrap";
import S1 from "../images/b1.jpg";
import S2 from "../images/b2.jpg";
import S3 from "../images/b3.jpg";
import S4 from "../images/b4.jpg";
import "../App.css";

export default function Slider() {
    return (
      <>
            <Carousel >
            <Carousel.Item className="sliderh">
                <Image src={S4} className="d-block w-100" />
                </Carousel.Item>
                <Carousel.Item className="sliderh">
                <Image src={S1} className="d-block w-100" />
                </Carousel.Item>
                <Carousel.Item className="sliderh">
                <Image src={S2} className="d-block w-100" />
                </Carousel.Item> 
                <Carousel.Item className="sliderh">
                <Image src={S3} className="d-block w-100" />
                </Carousel.Item>              
          </Carousel>
      </>
    )
};

const text = {textAlign: "center"};
