import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import "../../../src/images/team/pravin.png";
import pravin from "../../images/team/pravin.png";
import sharmishta from "../../images/team/sharmishta.png";
import priya from "../../images/team/priya dsouza 2.png";
import pravin1 from "../../images/team/pravin_dlima.png";
import taber from "../../images/team/taber dsilva.png";
import rajesh from "../../images/team/rajesh.png";
import abhishek from "../../images/team/abhishek.png";
import shikha from "../../images/team/shikha.png";
import dhanusha from "../../images/team/dhanusha.png";
import athiqr from "../../images/team/athiqr.png";
// import sandip from "../../images/team/sandip.png";
// import venkat from "../../images/team/venkat.png";
// import sunil from "../../images/team/sunil.png";
// import sumithra from "../../images/team/sumithra.png";
// import mohammed from "../../images/team/athiqr.png";

export default function Team() {
  return (
    <>
      <section className="dark">
      <h4 className="title  mb-5">MANAGEMENT TEAM</h4>
        <Container>
          <Row>
            <Col lg={3} md={3} sm={6}>
              <Card className="team animate__animated animate__zoomIn animate__delay-2s">
                <Card.Img variant="top" src={pravin} className="team_img" />
                <Card.Body>
                  <Card.Title>Pravin Fargade</Card.Title>
                  <Card.Text>
                    Director<br/>
                    Corporate Relationship, Operations & Exhibition
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <Card className="team animate__animated animate__zoomIn animate__delay-2s">
                <Card.Img variant="top" src={pravin1} className="team_img" />
                <Card.Body>
                  <Card.Title>Pravin Dilima</Card.Title>
                  <Card.Text>
                  Director <br/> Operations & Exhibition
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <Card className="team animate__animated animate__zoomIn animate__delay-2s">
                <Card.Img variant="top" src={sharmishta} className="team_img" />
                <Card.Body>
                  <Card.Title>Sharmishta Fargade</Card.Title>
                  <Card.Text>
                  Director <br/> HR
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <Card className="team animate__animated animate__zoomIn animate__delay-2s">
                <Card.Img variant="top" src={priya} className="team_img" />
                <Card.Body>
                  <Card.Title>Priya D’souza</Card.Title>
                  <Card.Text>
                  Director <br/> Finance
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <Card className="team animate__animated animate__zoomIn animate__delay-2s">
                <Card.Img variant="top" src={taber} className="team_img" />
                <Card.Body>
                  <Card.Title>Taber Ray D'Silva</Card.Title>
                  <Card.Text>
                  Director <br/> Strategic Management
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <Card className="team animate__animated animate__zoomIn animate__delay-2s">
                <Card.Img variant="top" src={rajesh} className="team_img" />
                <Card.Body>
                  <Card.Title>Rajesh Sharma</Card.Title>
                  <Card.Text>
                  General Manager <br/> Business Development & Operations

                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <Card className="team animate__animated animate__zoomIn animate__delay-2s">
                <Card.Img variant="top" src={abhishek} className="team_img" />
                <Card.Body>
                  <Card.Title>Abhishek Jain</Card.Title>
                  <Card.Text>
                    Regional Director - Delhi Business Development, Operations &
                    Exhibition
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <Card className="team animate__animated animate__zoomIn animate__delay-2s">
                <Card.Img variant="top" src={shikha} className="team_img" />
                <Card.Body>
                  <Card.Title>Shikha Jain</Card.Title>
                  <Card.Text>
                    Regional Director - Rajasthan Business Development,
                    Operations & Exhibition
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg={3} md={3} sm={6}>
              <Card className="team animate__animated animate__zoomIn animate__delay-2s">
                <Card.Img variant="top" src={dhanusha} className="team_img" />
                <Card.Body>
                  <Card.Title>Dhanusha Desai</Card.Title>
                  <Card.Text>
                    Regional Director - Gujarat Business Development, Operations
                    & Exhibition
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <Card className="team animate__animated animate__zoomIn animate__delay-2s">
                <Card.Img variant="top" src={athiqr} className="team_img" />
                <Card.Body>
                  <Card.Title>Athiqur Rahman</Card.Title>
                  <Card.Text>
                    Sr.Manager<br/> Business Development
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
           { /*
            <Col lg={3} md={3} sm={6}>
              <Card className="team animate__animated animate__zoomIn animate__delay-2s">
                <Card.Img variant="top" src={venkat} className="team_img" />
                <Card.Body>
                  <Card.Title>Venkat Ramdas</Card.Title>
                  <Card.Text>
                    Regional Director - Telangana Business Development
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <Card className="team animate__animated animate__zoomIn animate__delay-2s">
                <Card.Img variant="top" src={sunil} className="team_img" />
                <Card.Body>
                  <Card.Title>Sunil Kumar</Card.Title>
                  <Card.Text>
                    Regional Director - Telangana Business Development
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>

        </Container>
      </section>
    </>
  );
}
