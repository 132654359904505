import React from "react";
import { BrowserRouter , Route, Routes} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./modules/home/Home";
import AboutUs from "./modules/about/AboutUs";
import Contact from "./modules/contact/Contact";
import TopHeader from "./layout/TopHeader";
import Footer from "./layout/Footer";
import { Container } from "react-bootstrap";
import Gallery from "./modules/gallery/Gallery";
import Enquiry from "./modules/enquiry/Enquiry";
import OurServices from "./modules/services/OurServices";
import OurClients from "./modules/clients/OurClients";
import Team from "./modules/about/Team";


function NotFound() {
  return (
    <div>
      <TopHeader/>
      <Container>
      <h1>Page not found..</h1>
      </Container>
      <Footer/>
    </div>
  );
}

export default function App() {
  return (
    <>
    <TopHeader/>
      <BrowserRouter>
      <Routes>
          {/* <Route path="/" element={<Layout />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs/>} />
          <Route path="/OurServices" element={<OurServices/>}/>
          <Route path="/Contact" element={<Contact/>} />
          <Route path="/OurClients" element={<OurClients/>} />
          <Route path="/Gallery" element={<Gallery/>} />
          <Route path="/Enquiry" element={<Enquiry/>} />
          <Route path="*" element={<NotFound />} />
          <Route path="/Team" element={<Team/>} />
      </Routes>
    </BrowserRouter>
    <Footer/>
    </>
  );
}
