import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import Subheader from "../../layout/Subheader";
import AboutInfo from "./AboutInfo";
import Facts from "./Facts";
import Cities from "../home/Cities";
//import Footer from "../../layout/Footer";
import Team from "./Team";

export default function AboutUs() {
    return (
      <> 
     <Subheader />
     <AboutInfo />
     <Facts/>
     <Cities/>
     <Team/>
     {/* <Footer/> */}
      </>
    )
    } 