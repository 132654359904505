import React from "react";
//import emailjs from "@emailjs/browser";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row, Form, Container,  Button,} from "react-bootstrap";
//import App from "../home/Gallery1";
// import Footer from "../../layout/Footer";
// import TopHeader from "../../layout/TopHeader";
import InputField from "../../components/InputField";
//import EmptyRow from "../EmptyRow";
import "../style.css";

export default function Enquiry() {
  
  //  const form = useRef();
  // function sendEmail(e: any): void {
  //   e.preventDefault();

  //   emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', 'form.current', 'YOUR_PUBLIC_KEY')
  //     .then((result: any) => {
  //       console.log(result.text);
  //     }, (error: any) => {
  //       console.log(error.text);
  //     });
  // }
    
    return (
      <> 
       {/* <TopHeader/> */}
       <Container>
        <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <h2 className="mt-3">Feel Free to connect with us</h2>
         </Col>
         </Row>
         {/* <Row>
         <form ref={form} onSubmit={sendEmail} className="formbg">
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" />
        </form>
         </Row> */}
       <Form className="formbg">
        <Row>
        <Col lg={12} md={12} sm={12}><h4>Company Details</h4></Col>
          <Col lg={6} md={6} sm={12}>
            <InputField
              type="text"
              label="Company Name"
              placeholder="Company Name"
              isRequired
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <InputField
              type="text"
              label=" Person Name"
              placeholder="Company Person Name"
              isRequired
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <InputField
              type="number"
              label="Contact Number"
              placeholder="Contact Number"
              isRequired
            />
          </Col>
       
          <Col lg={6} md={6} sm={12}>
            <InputField
              type="email"
              label="Email Address"
              placeholder="Email Address"
              isRequired
            />
          </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12}>
            <Button variant="danger" className="mt-3">Send Email</Button>{' '}
            </Col>
          </Row>
      
  </Form>    
        </Container>
        {/* <Footer/> */}
      </>
    )
    } 