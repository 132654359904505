import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Container, Row,Col,Image } from "react-bootstrap";
import s1 from "../../images/ser1.png";
import s2 from "../../images/ser2.jpg"
import s3 from "../../images/ser3.jpg";
import s4 from "../../images/ser4.jpg"

export default function Services() {
    return (
      <>
      <Container>
      <h4 className="title mt-3 pt-3 mb-5">HONEYCOMB SERVICES</h4>
      <Row>
        <Col lg={3} md={3} sm={12} xs={12}>
           <Image src={s1} className="servicepic" />
           <p className="servicetitle"> CORPORATE EVENTS</p> 
           <p>Vast experience of the industry & a strong & qualified creative team <br/>
              <a href="#">Read More</a>
           </p>
           
        </Col>

        <Col lg={3} md={3} sm={12} xs={12}>
           <Image src={s2} className="servicepic" />
           <p className="servicetitle"> EXHIBITION DESIGN</p> 
           <p>The best-in-class craftsmen  <br/>
           <a href="#">Read More</a>
           </p>
        </Col>

        <Col lg={3} md={3} sm={12} xs={12}>
           <Image src={s3} className="servicepic"/>
           <p className="servicetitle"> BRAND ACTIVATION</p> 
           <p>State-of-the-art facilities & infrastructure with Smooth & hassle-free experience<br/> 
            <a href="#">Read More</a>
            </p>
        </Col>

        <Col lg={3} md={3} sm={12} xs={12}>
           <Image src={s4} className="servicepic"/>
           <p className="servicetitle"> BTL COMMUNICATION</p> 
           <p>State-of-the-art warehouse to store all your exhibition material<br/> 
           <a href="#">Read More</a>
            </p>
        </Col>
      </Row>  
      </Container>
      </>
    )
}