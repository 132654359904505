import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Container, Row,Col,Image } from "react-bootstrap";
import about from "../../images/about.gif";

export default function Abouth() {
    return (
      <>
      <Container>  
      <Row>
        <Col lg={6} md={4} sm={12} xs={12}>
            <h4 className="mt-5 animate__animated animate__fadeInLeft">ABOUT HONEYCOMB</h4>
            <p className="para animate__animated animate__fadeInLeft">           
            Honeycomb expo gives your brand a new identity and exhibits it to the masses. 
            Right from sketching a concept all through the finishing, we are one-stop solution for all your exhibiting needs. 
            Break the monotony with our unique designs. <br/><br/>
            {/* Sit back and relax while our team works on creating your brand image keeping your needs in mind.
            May that be Designing the Stall using new age software, Execution of the work by the experienced
            professional staff, provision of latest communication solutions like LED Walls, 
            Plasma TV and other gadgets, providing furniture on hire in the stall or any other 
            exhibition requirements, we got you covered all together for any event. <br/> */}

            We are the preferred choice in the industry for the following reasons:
            <ul>
              <li>Vast Experience Of The Industry</li>
              <li>A Strong & Qualified Creative Team</li>
              <li>The Best-In-Class Craftsmen </li>
              <li>State-Of-The-Art Facilities & Infrastructure </li>
              <li>Smooth & Hassle-Free Experience</li>
            </ul>
            </p>
        </Col>
      <Col lg={6} md={4} sm={12} xs={12}>
            <Image src={about} className="servicepic animate__animated animate__fadeInRight" />
      </Col>      
      </Row>  
    
      </Container>
      </>
    )}