import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Row,Col,Image, Container } from "react-bootstrap";
// import c1 from "../../images/clients/1.png";
// import c2 from "../../images/clients/2.png";
// import c3 from "../../images/clients/3.png";
// import c4 from "../../images/clients/4.png";
import c5 from "../../images/clients/5.png";
//import c6 from "../../images/clients/6.png";
import c7 from "../../images/clients/7.png";
import c8 from "../../images/clients/8.png";
import c9 from "../../images/clients/9.png";
import c10 from "../../images/clients/10.png";

import c11 from "../../images/clients/11.png";
import c12 from "../../images/clients/12.png";
// import c13 from "../../images/clients/13.png";
// import c14 from "../../images/clients/14.png";
// import c15 from "../../images/clients/15.png";
// import c16 from "../../images/clients/16.png";
// import c17 from "../../images/clients/17.png";
// import c18 from "../../images/clients/18.png";
// import c19 from "../../images/clients/19.png";

import c21 from "../../images/clients/21.png";
//import c22 from "../../images/clients/22.png";
import c24 from "../../images/clients/24.png";
import c25 from "../../images/clients/25.png";
import c26 from "../../images/clients/26.png";
import c27 from "../../images/clients/27.png";
import c28 from "../../images/clients/28.png";
import c29 from "../../images/clients/29.png";
import c34 from "../../images/clients/34.png";
import c35 from "../../images/clients/35.png";
import c45 from "../../images/clients/45.png";
import c46 from "../../images/clients/46.png";
import c47 from "../../images/clients/47.png";
import c48 from "../../images/clients/48.png";
import c51 from "../../images/clients/51.png";
import c52 from "../../images/clients/52.png";
import c53 from "../../images/clients/53.png";
import c54 from "../../images/clients/54.png";


export default function Clients() {
    return (
      <>
        <section className="dark"> 
       <Container>     
        <h4 className="title  mb-5">EXHIBITIONS WE WORK IN</h4>
        <Row className="">
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c5} className="client" style={{width:"100%"}} />       
        </Col>
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c8} className="client" style={{width:"100%"}} />       
        </Col>
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c7} className="client" style={{width:"100%"}} />       
        </Col>
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c21} className="client" style={{width:"100%"}}/>       
        </Col> 
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c24} className="client" style={{width:"100%"}} />       
        </Col>  
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c25} className="client" style={{width:"100%"}} />       
        </Col> 
       </Row>

       <Row className="mt-2">  
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c28} className="client" style={{width:"100%"}} />       
        </Col>
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c29} className="client" style={{width:"100%"}}/>       
        </Col> 
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c47} className="client" style={{width:"100%"}}/>   
        </Col>
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c48} className="client" style={{width:"100%"}}/>   
        </Col>
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c51} className="client" style={{width:"100%"}}/>       
        </Col>   
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c52} className="client" style={{width:"100%"}}/>       
        </Col>
       </Row>

       <h4 className="title  mb-5 mt-5">OUR VALUABLE CLIENTS</h4>

       <Row  className="mt-2">    
       <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c10} className="client" style={{width:"100%"}} />       
        </Col>      
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c11} className="client" style={{width:"100%"}} />       
        </Col>   
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c12} className="client" style={{width:"100%"}} />       
        </Col>    
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c9} className="client" style={{width:"100%"}}/>       
        </Col>
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c26} className="client" style={{width:"100%"}}/>       
        </Col>
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c27} className="client" style={{width:"100%"}} />       
        </Col>
        </Row>

        <Row  className="mt-2 pb-5">  
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c34} className="client" style={{width:"100%"}} />       
        </Col>
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c35} className="client" style={{width:"100%"}} />       
        </Col>
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c45} className="client" style={{width:"100%"}}/>       
        </Col>   
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c46} className="client" style={{width:"100%"}}/>       
        </Col>
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c53} className="client" style={{width:"100%"}}/>       
        </Col>
        <Col lg={2} md={2} sm={2} xs={6} className="p-0">
        <Image src={c54} className="client" style={{width:"100%"}} />       
        </Col>
        
        </Row>
       
       </Container>
       </section>
      </>
    )
    } 