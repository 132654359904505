import React from "react";
import honey from "../images/honey1.png";
import call from "../images/call.png";
import mail from "../images/mail.png";
import map from "../images/map.png";
import fb from "../images/fb.png";
import insta from "../images/instag.png";
import {
  Navbar,
  Nav,
  Image,
  Row,
  Col,
} from "react-bootstrap";
import "../App.css";

export default function TopHeader() {
  return (
    <>
      {/* Top Bar */}

      <div className="layout">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={3} sm={12}>
            <p>
              <Image src={call} className="icons" /> 91528 93009 | 99307 03338{" "}
            </p>
          </Col>
          <Col xxl={7} xl={7} lg={7} md={7} sm={12} style={{marginTop:"-15px"}}>
            <p>
              <Image src={mail} className="icons" /> Harshitasachdev@digitalcrown.in | Rajeshsharma@digitalcrown.in
            </p>
          </Col>
          {/* <Col xxl={2} xl={2} lg={2} md={2} sm={12}>
            <p>
              <a href="https://goo.gl/maps/AV7GMu1qbhRZX3Fu8" rel="noreferrer" target="blank"><Image src={map} className="icons" /></a> Our Location{" "}
            </p>
          </Col> */}
          
          <Col xxl={2} xl={2} lg={2} md={2} sm={12}>
            <p>
              Connect with us <a target="_blank" rel="noreferrer" href="https://www.facebook.com/honeycombexpo"><Image src={fb} className="icons" /></a> | 
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/honeycombexhibits/"><Image src={insta} className="icons" /></a>
            </p>
          </Col>
        </Row>
      </div>

      {/* Navbar */}
      <Navbar id="nav" expand="sm" bg="dark" variant="dark" className="layout" sticky="top">
        <Navbar.Brand>
          <Image src={honey} className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">HOME</Nav.Link>
            <Nav.Link href="/OurServices">SERVICES</Nav.Link>
            {/* <NavDropdown title="SERVICES" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Corporate Events</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2"> Brand Activation </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Exhibition Design</NavDropdown.Item> 
            <NavDropdown.Item href="#action/3.3">Media Planning</NavDropdown.Item>           
          </NavDropdown> */}
            {/* <Nav.Link href="/Gallery">GALLERY</Nav.Link> */}
            <Nav.Link href="/Gallery">PORTFOLIO</Nav.Link>
            <Nav.Link href="/AboutUs">ABOUT US</Nav.Link>
            <Nav.Link href="/OurClients">OUR CLIENTS</Nav.Link>
            <Nav.Link href="/Contact">CONTACT US</Nav.Link>
            {/* <Nav.Link href="/Enquiry">ENQUIRY</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

//const text = { textAlign: "center" };
