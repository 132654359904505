import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Container, Row,Col,Image } from "react-bootstrap";
import ser1 from "../../images/services/stall.png";
import ser2 from "../../images/services/fabrication.png";
import ser3 from "../../images/services/production.png";
import ser4 from "../../images/services/warehouse.png";

export default function CoreServices() {
    return (
      <>
      <Container>
      <div className="text-center">
      <Row>
        <Col lg={3} md={3} sm={12} xs={12}>
           <Image src={ser1} className="servicepic" />
           <h4>DESIGNING EXHIBITION STALL CONCEPT</h4>
           <p className="servicetitle"> Vast experience of the industry & a strong & qualified creative team<br/>
           </p>           
        </Col>

        <Col lg={3} md={3} sm={12} xs={12}>
           <Image src={ser2} className="servicepic" />
           <h4>EXHIBITION STALL FABRICATION</h4>
           <p className="servicetitle"> The best-in-class craftsmen<br/>
           </p>           
        </Col>

        <Col lg={3} md={3} sm={12} xs={12}>
           <Image src={ser3} className="servicepic" />
           <h4>PRODUCTION</h4>
           <p className="servicetitle"> State-of-the-art facilities & infrastructure with Smooth & hassle-free experience<br/>
           </p>           
        </Col>

        <Col lg={3} md={3} sm={12} xs={12}>
           <Image src={ser4} className="servicepic" />
           <h4>WAREHOUSING</h4>
           <p className="servicetitle"> State-of-the-art warehouse to store all your exhibition material<br/>
           </p>           
        </Col>
      </Row>  
      </div>
      </Container>
      </>
    )
}