import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import Locations from "./Locations";
import { Container } from "react-bootstrap";
import Subheader from "../../layout/Subheader";
//import Footer from "../../layout/Footer";

export default function Contact() {
    return (
      <> 
      <Subheader/> 
      <Container>       
            <h3>Honeycomb Network</h3>
            <Locations/>
            <iframe src="https://www.google.com/maps/d/embed?mid=1nTXRWuqeknTZH2-V3rbC9i704dUiWdQ&ehbc=2E312F" width="100%" height="480"></iframe>
            
     </Container>
     {/* <Footer/> */}
      </>
    )
    } 