import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row} from "react-bootstrap";
import App from "../home/Gallery1";
// import Footer from "../../layout/Footer";
// import TopHeader from "../../layout/TopHeader";

export default function Gallery() {
    return (
      <> 
       {/* <TopHeader/> */}
        <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
         <App/>
         </Col>   
        </Row>
        {/* <Footer/> */}
      </>
    )
    } 