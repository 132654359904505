import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Col, Row} from "react-bootstrap";
//import map from "../../images/map.png";
import EmptyRow from "../EmptyRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function Locations() {
    return (
      <> 
            <Row>
            <Col xl={6} lg={6} md={6} sm={6}> 
                     <span className="location_title"> Registered Office:</span> <br/> 
                     <FontAwesomeIcon icon={faLocationDot as IconProp} /> D 204, Venezia, Casa Bella Gold, Palava, Dombivali E, 
                     Thane 421204, Maharashtra.<br/>
                     <FontAwesomeIcon icon={faEnvelope as IconProp} /> Harshitasachdev@digitalcrown.in | Rajeshsharma@digitalcrown.in
             </Col>
             <Col xl={6} lg={6} md={6} sm={6}> 
                     <span className="location_title">Head Office:</span> <br/> 
                        <FontAwesomeIcon icon={faLocationDot as IconProp} /> 13th Floor, Building No. A1 Rupa Solitaire, Unit- 1308, Sector, 2, Millenium Business Park, Mahape, Navi Mumbai, Maharashtra 400710.<br/>
                        <a href="tel:+9930703338"><FontAwesomeIcon icon={faPhone as IconProp} /></a> Pravin Fargade: +91 99307 03338<br/>
                        {/* <a href="tel:+9930703338"><FontAwesomeIcon icon={faPhone as IconProp} /></a> Santosh Chaudhary: +91 97691 02203<br/> */}
                        <a href="mailto:Harshitasachdev@digitalcrown.in"><FontAwesomeIcon icon={faEnvelope as IconProp} /></a> Harshitasachdev@digitalcrown.in | Rajeshsharma@digitalcrown.in
             </Col>
            </Row>
            <EmptyRow/>
            <Row>
            <Col xl={6} lg={6} md={6} sm={6}> 
                     <span className="location_title">Pune Office:</span> <br/> 
                     <FontAwesomeIcon icon={faLocationDot as IconProp} /> No 10, Building No. 3, Agresan Society, kundan nagar, 
                        Kasarwadi Pune 411012, Maharashtra.<br/>
                        <FontAwesomeIcon icon={faPhone as IconProp} />Sandeep Baste: +91 99224 31614<br/>
                        <FontAwesomeIcon icon={faEnvelope as IconProp} /> Harshitasachdev@digitalcrown.in | Rajeshsharma@digitalcrown.in
             </Col>
             <Col xl={6} lg={6} md={6} sm={6}> 
                     <span className="location_title">Surat Office:</span> <br/> 
                   <FontAwesomeIcon icon={faLocationDot as IconProp} /> B/603, Mangalpuri Apartment, Raj Palace, 
                    Anand Mahal Toad, Surat 395009, Gujarat.<br/>
                    <FontAwesomeIcon icon={faPhone as IconProp} /> Dhanusha Desai: +91 97691 21876<br/>
                    <FontAwesomeIcon icon={faEnvelope as IconProp} /> Harshitasachdev@digitalcrown.in | Rajeshsharma@digitalcrown.in
             </Col>
            </Row>
            <EmptyRow/>
            <Row>         
             <Col xl={6} lg={6} md={6} sm={6}> 
                     <span className="location_title">Jaipur Office:</span> <br/> 
                     <FontAwesomeIcon icon={faLocationDot as IconProp} />610, Precious Mall, M D Road, Jaipur<br/>
                     <FontAwesomeIcon icon={faPhone as IconProp} /> Shikha Jain: +91 93520 46838<br/>
                        <FontAwesomeIcon icon={faEnvelope as IconProp} /> Harshitasachdev@digitalcrown.in | Rajeshsharma@digitalcrown.in
             </Col>
             <Col xl={6} lg={6} md={6} sm={6}> 
                     <span className="location_title">Delhi Office:</span> <br/> 
                     <FontAwesomeIcon icon={faLocationDot as IconProp} />  RZ-A 59, Street No. 4, Block A, Mahipalpur Extension, New Delhi 110037.<br/>
                     <FontAwesomeIcon icon={faPhone as IconProp} /> Abhishek Jain: +91 98104 54429<br/>
                    <FontAwesomeIcon icon={faEnvelope as IconProp} /> Harshitasachdev@digitalcrown.in | Rajeshsharma@digitalcrown.in
             </Col>
            </Row>
            <EmptyRow/>
            <Row>           
             <Col xl={6} lg={6} md={6} sm={6}> 
                     <span className="location_title">Bangalore Office:</span> <br/> 
                     <FontAwesomeIcon icon={faLocationDot as IconProp} /> No.33, 6th Cross Road Kanaka Nagar, RT Nagar Post, Bangalore, 560032<br/>
                    <FontAwesomeIcon icon={faPhone as IconProp} />Rahaman: +91 98444 41471 | +91 80796 04943<br/>
                    <FontAwesomeIcon icon={faEnvelope as IconProp} /> Harshitasachdev@digitalcrown.in | Rajeshsharma@digitalcrown.in
             </Col>
             <Col xl={6} lg={6} md={6} sm={6}> 
                     <span className="location_title">Hyderabad Office: </span> <br/> 
                     <FontAwesomeIcon icon={faLocationDot as IconProp} /> NCL Godavari Apartment, D- Block, D-512, Petbasheerabad, 
                    Behind Konark Hospital, Pipeline road, Hyderabad 500067.<br/>
                    <FontAwesomeIcon icon={faPhone as IconProp} /> V Venkat Ramdas: +91 99633 00303<br/>
                    <FontAwesomeIcon icon={faEnvelope as IconProp} /> Harshitasachdev@digitalcrown.in | Rajeshsharma@digitalcrown.in
             </Col>
            </Row>
            <EmptyRow/>
           
           
      </>
    )
    } 