import React from "react";
import honey from "../images/honey1.png";
import call from "../images/fcall.png";
import mail from "../images/fmail.png";
import map from "../images/fmap.png";
import web from "../images/fweb.png"
import fb from "../images/fb.png";
import insta from "../images/instag.png";
import {Image, Row, Col, Container} from "react-bootstrap";
import "../App.css";


export default function Footer() {
  return (
    <>
    <section className="footerbg">
    <Container>
       <Row>
        <Col lg={4} md={4} sm={12} xs={12}>
          <Image src={honey} className="footerlogo"/>
          <p className="mt-3">We have a “Purpose” to exhibit.
             Exhibition stands, Events, All Promotional Activities including 
             advertising, retail, branding and much more</p>
             <p><span className="footertitle">Service Areas:</span> Mumbai | Pune | Chennai | Banglore | Hyderabad | Ahmedabad</p>
        </Col>
        <Col lg={3} md={3} sm={6} xs={6}>
          <h6 className="pt-4 footertitle">HONEYCOMB SERVICES</h6>
          <ul >
            <li>CORPORATE EVENTS</li>
            <br/>
            <li>EXHIBITION DESIGN</li>
            <br/>
            <li>BRAND ACTIVATION</li>
            <br/>
            <li>BTL COMMUNICATION</li>
          </ul>
        </Col>
        <Col lg={2} md={2} sm={6} xs={6}>
          <h6 className="pt-4 footertitle">QUICK LINKS</h6>
          <ul>
            <li>Home</li>
            <li>About</li>
            <li>Services</li>
            <li>Gallery</li>
            <li>Portfolio</li>
            <li>Contact</li>
          </ul>
        </Col>
       
        <Col lg={3} md={3} sm={12} xs={12}>
        <h6 className="pt-4 footertitle">REACH US</h6>
        <span className="footerp"><Image src={map} className="icons"/>  Rupa Solitaire, 13th Floor, MBP, Mahape, Navi Mumbai 400 710.</span><br/>
        <span className="footerp"><Image src={call} className="icons"/> +91 91528 93009</span><br/>
        <span className="footerp"><Image src={mail} className="icons"/> Harshitasachdev@digitalcrown.in | Rajeshsharma@digitalcrown.in</span><br/>
        <span className="footerp"><Image src={web} className="icons"/> wwww.honeycombexhibits.com</span><br/>
        <h6 className="pt-4 footertitle">FOLLOW US</h6>
        <span> <a href="https://www.facebook.com/honeycombexpo"> <Image src={fb} className="icons" /></a> | 
               <a href="https://www.instagram.com/honeycombexhibits"><Image src={insta} className="icons" /></a></span>
        </Col>
       </Row>
       </Container>
    </section>
    </>
  )
}
