import React from "react";
import { Image } from "react-bootstrap";
import s1 from "../../images/ser1.png";
export const photos = [
  {
    src: require("../../../src/images/gallery/1.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/2.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/3.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/4.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/5.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/6.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/7.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/8.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/9.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/10.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/11.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/12.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/13.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/14.png"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/15.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/16.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/17.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/18.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/19.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/20.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/21.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/22.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/23.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/24.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/25.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/26.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/27.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/28.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/29.jpg"),
    width: 3,
    height: 2,
  },
  {
    src: require("../../../src/images/gallery/30.jpg"),
    width: 3,
    height: 2,
  },
];
export const imageList = [
  {
    id: 1,
    imgUrl: require("../../../src/images/portfolio/Aquila/Aiama_Bangalore/a5.jpeg"),
    title: "Aquila",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/Aiama_Bangalore/a1.jpeg"),
        subHeader: "Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/Aiama_Bangalore/a2.jpeg"),
        subHeader: "Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/Aiama_Bangalore/a3.jpeg"),
        subHeader: "Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/Aiama_Bangalore/a5.jpeg"),
        subHeader: "Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/Aiama_Bangalore/a6.jpeg"),
        subHeader: "Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/FAFAI_Kolkata/f1.jpeg"),
        subHeader: "FAFAI, Kolkata",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/FAFAI_Kolkata/f2.jpeg"),
        subHeader: "FAFAI, Kolkata",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/FAFAI_Kolkata/f3.jpeg"),
        subHeader: "FAFAI, Kolkata",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/FAFAI_Kolkata/f4.jpeg"),
        subHeader: "FAFAI, Kolkata",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/FAFAI_Kolkata/f5.jpeg"),
        subHeader: "FAFAI, Kolkata",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/FAFAI_Kolkata/f6.jpeg"),
        subHeader: "FAFAI, Kolkata",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/FAFAI_Kolkata/f7.jpeg"),
        subHeader: "FAFAI, Kolkata",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/FAFAI_Kolkata/f8.jpeg"),
        subHeader: "FAFAI, Kolkata",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/FAFAI_Kolkata/f9.jpeg"),
        subHeader: "FAFAI, Kolkata",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/FAFAI_Kolkata/f10.jpeg"),
        subHeader: "FAFAI, Kolkata",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/FAFAI_Kolkata/f11.jpeg"),
        subHeader: "FAFAI, Kolkata",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/World_Shisha_Dubai/w1.jpeg"),
        subHeader: "World Shisha, Dubai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/World_Shisha_Dubai/w2.jpeg"),
        subHeader: "World Shisha, Dubai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/World_Shisha_Dubai/w3.jpeg"),
        subHeader: "World Shisha, Dubai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/World_Shisha_Dubai/w4.jpeg"),
        subHeader: "World Shisha, Dubai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/World_Shisha_Dubai/w5.jpeg"),
        subHeader: "World Shisha, Dubai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/World_Shisha_Dubai/w6.jpeg"),
        subHeader: "World Shisha, Dubai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/World_Shisha_Dubai/w7.jpeg"),
        subHeader: "World Shisha, Dubai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/World_Shisha_Dubai/w8.jpeg"),
        subHeader: "World Shisha, Dubai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/World_Shisha_Dubai/w9.jpeg"),
        subHeader: "World Shisha, Dubai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aquila/World_Shisha_Dubai/w10.jpeg"),
        subHeader: "World Shisha, Dubai",
      },
    ],
  },
  {
    id: 2,
    imgUrl: require("../../../src/images/portfolio/Arham_Bangales_SJS/a1.jpeg"),
    title: "Arham Bangles, SJS",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Arham_Bangales_SJS/a1.jpeg"),
        subHeader: "Arham Bangles, SJS",
      },
    ],
  },
  {
    id: 3,
    imgUrl: require("../../../src/images/portfolio/Aviva_Paperworld_Mumbai/a1.jpeg"),
    title: "Aviva, Paperworld, Mumbai",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Aviva_Paperworld_Mumbai/a1.jpeg"),
        subHeader: "Aviva, Paperworld, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aviva_Paperworld_Mumbai/a2.jpeg"),
        subHeader: "Aviva, Paperworld, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Aviva_Paperworld_Mumbai/a3.jpeg"),
        subHeader: "Aviva, Paperworld, Mumbai",
      },
    ],
  },
  {
    id: 4,
    imgUrl: require("../../../src/images/portfolio/Axboom_Aister_LED_Expo_Mumbai/a3.jpeg"),
    title: "Axboom (Aister), LED Expo, Mumbai",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Axboom_Aister_LED_Expo_Mumbai/a1.jpeg"),
        subHeader: "Axboom (Aister), LED Expo, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Axboom_Aister_LED_Expo_Mumbai/a2.jpeg"),
        subHeader: "Axboom (Aister), LED Expo, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Axboom_Aister_LED_Expo_Mumbai/a3.jpeg"),
        subHeader: "Axboom (Aister), LED Expo, Mumbai",
      },
    ],
  },
  {
    id: 5,
    imgUrl: require("../../../src/images/portfolio/Balaji_Gartex_Mumbai/b1.jpeg"),
    title: "Balaji, Gartex, Mumbai",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Balaji_Gartex_Mumbai/b1.jpeg"),
        subHeader: "Balaji, Gartex, Mumbai",
      },
    ],
  },
  {
    id: 6,
    imgUrl: require("../../../src/images/portfolio/Banner_Engineering/Automation_expo_Mumbai_2022/a1.jpeg"),
    title: "Banner Engineering",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Banner_Engineering/Automation_expo_Mumbai_2022/a1.jpeg"),
        subHeader: "Automation expo, Mumbai, 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Banner_Engineering/Automation_expo_Mumbai_2022/a2.jpeg"),
        subHeader: "Automation expo, Mumbai, 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Banner_Engineering/Automation_expo_Mumbai_2022/a3.jpeg"),
        subHeader: "Automation expo, Mumbai, 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Banner_Engineering/Automation_expo_Chennai,_2023/a1.jpeg"),
        subHeader: "Automation expo, Chennai, 2023",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Banner_Engineering/Automation_expo_Chennai,_2023/a2.jpeg"),
        subHeader: "Automation expo, Chennai, 2023",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Banner_Engineering/Automation_expo_Chennai,_2023/a3.jpeg"),
        subHeader: "Automation expo, Chennai, 2023",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Banner_Engineering/Automation_expo_Chennai,_2023/a4.jpeg"),
        subHeader: "Automation expo, Chennai, 2023",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Banner_Engineering/Automation_expo_Chennai,_2023/a5.jpeg"),
        subHeader: "Automation expo, Chennai, 2023",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Banner_Engineering/Automation_expo_Chennai,_2023/a6.jpeg"),
        subHeader: "Automation expo, Chennai, 2023",
      },
    ],
  },
  {
    id: 7,
    imgUrl: require("../../../src/images/portfolio/Bharatpe_Fintech_Mumbai/b1.jpeg"),
    title: "Bharatpe, Fintech, Mumbai",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Bharatpe_Fintech_Mumbai/b1.jpeg"),
        subHeader: "Bharatpe, Fintech, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Bharatpe_Fintech_Mumbai/b2.jpeg"),
        subHeader: "Bharatpe, Fintech, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Bharatpe_Fintech_Mumbai/b3.jpeg"),
        subHeader: "Bharatpe, Fintech, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Bharatpe_Fintech_Mumbai/b4.jpeg"),
        subHeader: "Bharatpe, Fintech, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Bharatpe_Fintech_Mumbai/b5.jpeg"),
        subHeader: "Bharatpe, Fintech, Mumbai",
      },
    ],
  },
  {
    id: 8,
    imgUrl: require("../../../src/images/portfolio/Blue_star/India_Horeca_Expo_Coimbatore/a1.jpeg"),
    title: "Blue Star",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Blue_star/India_Horeca_Expo_Coimbatore/a1.jpeg"),
        subHeader: "India Horeca Expo, Coimbatore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Blue_star/India_Horeca_Expo_Coimbatore/a2.jpeg"),
        subHeader: "India Horeca Expo, Coimbatore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Blue_star/India_Horeca_Expo_Coimbatore/a3.jpeg"),
        subHeader: "India Horeca Expo, Coimbatore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Blue_star/India_Horeca_Expo_Coimbatore/a4.jpeg"),
        subHeader: "India Horeca Expo, Coimbatore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Blue_star/India_Horeca_Expo_Coimbatore/a5.jpeg"),
        subHeader: "India Horeca Expo, Coimbatore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Blue_star/Aakar_Hospitality_Expo_22_24_June_Ahmedabad/a1.jpeg"),
        subHeader: "Aakar Hospitality Expo, 22-24 June, Ahmedabad",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Blue_star/Aakar_Hospitality_Expo_22_24_June_Ahmedabad/a2.jpeg"),
        subHeader: "Aakar Hospitality Expo, 22-24 June, Ahmedabad",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Blue_star/Aakar_Hospitality_Expo_22_24_June_Ahmedabad/a3.jpeg"),
        subHeader: "Aakar Hospitality Expo, 22-24 June, Ahmedabad",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Blue_star/Aakar_Hospitality_Expo_22_24_June_Ahmedabad/a4.jpeg"),
        subHeader: "Aakar Hospitality Expo, 22-24 June, Ahmedabad",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Blue_star/Aakar_Hospitality_Expo_22_24_June_Ahmedabad/a5.jpeg"),
        subHeader: "Aakar Hospitality Expo, 22-24 June, Ahmedabad",
      },
    ],
  },
  {
    id: 9,
    imgUrl: require("../../../src/images/portfolio/Deepak_refinery_silver_show_of_India_Bangalore/b1.jpeg"),
    title: "Deepak refinery, silver show of India Bangalore",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Deepak_refinery_silver_show_of_India_Bangalore/b1.jpeg"),
        subHeader: "Deepak refinery, silver show of India Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Deepak_refinery_silver_show_of_India_Bangalore/b2.jpeg"),
        subHeader: "Deepak refinery, silver show of India Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Deepak_refinery_silver_show_of_India_Bangalore/b3.jpeg"),
        subHeader: "Deepak refinery, silver show of India Bangalore",
      },
    ],
  },
  {
    id: 10,
    imgUrl: require("../../../src/images/portfolio/Digital_Innovation_Media_expo_Mumbai/b1.jpeg"),
    title: "Digital Innovation, Media expo, Mumbai",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Digital_Innovation_Media_expo_Mumbai/b1.jpeg"),
        subHeader: "Digital Innovation, Media expo, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Digital_Innovation_Media_expo_Mumbai/b2.jpeg"),
        subHeader: "Digital Innovation, Media expo, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Digital_Innovation_Media_expo_Mumbai/b3.jpeg"),
        subHeader: "Digital Innovation, Media expo, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Digital_Innovation_Media_expo_Mumbai/b4.jpeg"),
        subHeader: "Digital Innovation, Media expo, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Digital_Innovation_Media_expo_Mumbai/b5.jpeg"),
        subHeader: "Digital Innovation, Media expo, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Digital_Innovation_Media_expo_Mumbai/b6.jpeg"),
        subHeader: "Digital Innovation, Media expo, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Digital_Innovation_Media_expo_Mumbai/b7.jpeg"),
        subHeader: "Digital Innovation, Media expo, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Digital_Innovation_Media_expo_Mumbai/b8.jpeg"),
        subHeader: "Digital Innovation, Media expo, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Digital_Innovation_Media_expo_Mumbai/b9.jpeg"),
        subHeader: "Digital Innovation, Media expo, Mumbai",
      },
    ],
  },
  {
    id: 11,
    imgUrl: require("../../../src/images/portfolio/Fragomatrix_Aiama_Bangalore/f1.jpeg"),
    title: "Fragomatrix, Aiama, Bangalore",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Fragomatrix_Aiama_Bangalore/f1.jpeg"),
        subHeader: "Fragomatrix, Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Fragomatrix_Aiama_Bangalore/f2.jpeg"),
        subHeader: "Fragomatrix, Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Fragomatrix_Aiama_Bangalore/f3.jpeg"),
        subHeader: "Fragomatrix, Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Fragomatrix_Aiama_Bangalore/f4.jpeg"),
        subHeader: "Fragomatrix, Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Fragomatrix_Aiama_Bangalore/f5.jpeg"),
        subHeader: "Fragomatrix, Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Fragomatrix_Aiama_Bangalore/f6.jpeg"),
        subHeader: "Fragomatrix, Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Fragomatrix_Aiama_Bangalore/f7.jpeg"),
        subHeader: "Fragomatrix, Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Fragomatrix_Aiama_Bangalore/f8.jpeg"),
        subHeader: "Fragomatrix, Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Fragomatrix_Aiama_Bangalore/f9.jpeg"),
        subHeader: "Fragomatrix, Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Fragomatrix_Aiama_Bangalore/f10.jpeg"),
        subHeader: "Fragomatrix, Aiama, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Fragomatrix_Aiama_Bangalore/f11.jpeg"),
        subHeader: "Fragomatrix, Aiama, Bangalore",
      },
    ],
  },
  {
    id: 12,
    imgUrl: require("../../../src/images/portfolio/Futura_sinks_Acetech_Mumbai/f1.jpeg"),
    title: "Futura sinks, Acetech, Mumbai",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Futura_sinks_Acetech_Mumbai/f1.jpeg"),
        subHeader: "Futura sinks, Acetech, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Futura_sinks_Acetech_Mumbai/f2.jpeg"),
        subHeader: "Futura sinks, Acetech, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Futura_sinks_Acetech_Mumbai/f3.jpeg"),
        subHeader: "Futura sinks, Acetech, Mumbai",
      },
    ],
  },
  {
    id: 13,
    imgUrl: require("../../../src/images/portfolio/Guru_Kripa_Studio_JJS_Jaipur/g1.jpeg"),
    title: "Guru Kripa Studio, JJS, Jaipur",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Guru_Kripa_Studio_JJS_Jaipur/g1.jpeg"),
        subHeader: "Guru Kripa Studio, JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Guru_Kripa_Studio_JJS_Jaipur/g2.jpeg"),
        subHeader: "Guru Kripa Studio, JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Guru_Kripa_Studio_JJS_Jaipur/g3.jpeg"),
        subHeader: "Guru Kripa Studio, JJS, Jaipur",
      },
    ],
  },
  {
    id: 14,
    imgUrl: require("../../../src/images/portfolio/Help_us_green_Paperworld_expo_Mumbai/h1.png"),
    title: "Help us green, Paperworld expo, Mumbai",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Help_us_green_Paperworld_expo_Mumbai/h1.png"),
        subHeader: "Help us green, Paperworld expo, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Help_us_green_Paperworld_expo_Mumbai/h2.png"),
        subHeader: "Help us green, Paperworld expo, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Help_us_green_Paperworld_expo_Mumbai/h3.jpeg"),
        subHeader: "Help us green, Paperworld expo, Mumbai",
      },
    ],
  },
  {
    id: 15,
    imgUrl: require("../../../src/images/portfolio/Karni_Jewellers_JJS_Jaipur/k1.jpeg"),
    title: "Karni Jewellers, JJS, Jaipur",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Karni_Jewellers_JJS_Jaipur/k1.jpeg"),
        subHeader: "Karni Jewellers, JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Karni_Jewellers_JJS_Jaipur/k2.jpeg"),
        subHeader: "Karni Jewellers, JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Karni_Jewellers_JJS_Jaipur/k3.jpeg"),
        subHeader: "Karni Jewellers, JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Karni_Jewellers_JJS_Jaipur/k4.jpeg"),
        subHeader: "Karni Jewellers, JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Karni_Jewellers_JJS_Jaipur/k5.jpeg"),
        subHeader: "Karni Jewellers, JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Karni_Jewellers_JJS_Jaipur/k6.jpeg"),
        subHeader: "Karni Jewellers, JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Karni_Jewellers_JJS_Jaipur/k7.jpeg"),
        subHeader: "Karni Jewellers, JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Karni_Jewellers_JJS_Jaipur/k8.jpeg"),
        subHeader: "Karni Jewellers, JJS, Jaipur",
      },
    ],
  },
  {
    id: 16,
    imgUrl: require("../../../src/images/portfolio/Misri_Gold/SJS_Bangalore_September/m1.jpeg"),
    title: "Misri Gold",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/SJS_Bangalore_September/m1.jpeg"),
        subHeader: "SJS, Bangalore, September",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/SJS_Bangalore_September/m2.jpeg"),
        subHeader: "SJS, Bangalore, September",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/SJS_Bangalore_March/m1.jpeg"),
        subHeader: "SJS, Bangalore, March",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/SJS_Bangalore_March/m2.jpeg"),
        subHeader: "SJS, Bangalore, March",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/SJS_Bangalore_March/m3.jpeg"),
        subHeader: "SJS, Bangalore, March",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/SJS_Bangalore_March/m4.jpeg"),
        subHeader: "SJS, Bangalore, March",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/SJS_Bangalore_March/m5.jpeg"),
        subHeader: "SJS, Bangalore, March",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/SJS_Bangalore_March/m6.jpeg"),
        subHeader: "SJS, Bangalore, March",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/JJS_Jaipur/m1.jpeg"),
        subHeader: "JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/JJS_Jaipur/m2.jpeg"),
        subHeader: "JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/JJS_Jaipur/m3.jpeg"),
        subHeader: "JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/JJS_Jaipur/m4.jpeg"),
        subHeader: "JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/JJS_Jaipur/m5.jpeg"),
        subHeader: "JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/JJS_Jaipur/m6.jpeg"),
        subHeader: "JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/JJS_Jaipur/m7.jpeg"),
        subHeader: "JJS, Jaipur",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Misri_Gold/JJS_Jaipur/m8.jpeg"),
        subHeader: "JJS, Jaipur",
      },
    ],
  },
  {
    id: 17,
    imgUrl: require("../../../src/images/portfolio/Modern_Denim_Gartex_Mumbai/m1.jpeg"),
    title: "Modern Denim Gartex Mumbai",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Modern_Denim_Gartex_Mumbai/m1.jpeg"),
        subHeader: "Modern Denim Gartex Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Modern_Denim_Gartex_Mumbai/m2.jpeg"),
        subHeader: "Modern Denim Gartex Mumbai",
      },
    ]
  },
  {
    id: 18,
    imgUrl: require("../../../src/images/portfolio/MP_Jewels_SJS_Bangalore/m1.png"),
    title: "MP Jewels, SJS, Bangalore",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/MP_Jewels_SJS_Bangalore/m1.png"),
        subHeader: "MP Jewels, SJS, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/MP_Jewels_SJS_Bangalore/m2.jpeg"),
        subHeader: "MP Jewels, SJS, Bangalore",
      },
    ]
  },
  {
    id: 19,
    imgUrl: require("../../../src/images/portfolio/Navkar_silver_silver_show_of_India_Bangalore/n1.jpeg"),
    title: "Navkar silver, silver show of India, Bangalore",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Navkar_silver_silver_show_of_India_Bangalore/n1.jpeg"),
        subHeader: "Navkar silver, silver show of India, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Navkar_silver_silver_show_of_India_Bangalore/n2.jpeg"),
        subHeader: "Navkar silver, silver show of India, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Navkar_silver_silver_show_of_India_Bangalore/n3.jpeg"),
        subHeader: "Navkar silver, silver show of India, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Navkar_silver_silver_show_of_India_Bangalore/n4.jpeg"),
        subHeader: "Navkar silver, silver show of India, Bangalore",
      },
    ]
  },
  {
    id: 20,
    imgUrl: require("../../../src/images/portfolio/Nasher_miles_Gifts_expo_Delhi/n1.jpeg"),
    title: "Nasher miles, Gifts expo, Delhi",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Nasher_miles_Gifts_expo_Delhi/n1.jpeg"),
        subHeader: "Nasher miles, Gifts expo, Delhi",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Nasher_miles_Gifts_expo_Delhi/n2.jpeg"),
        subHeader: "Nasher miles, Gifts expo, Delhi",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Nasher_miles_Gifts_expo_Delhi/n3.jpeg"),
        subHeader: "Nasher miles, Gifts expo, Delhi",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Nasher_miles_Gifts_expo_Delhi/n4.jpeg"),
        subHeader: "Nasher miles, Gifts expo, Delhi",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Nasher_miles_Gifts_expo_Delhi/n5.jpeg"),
        subHeader: "Nasher miles, Gifts expo, Delhi",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Nasher_miles_Gifts_expo_Delhi/n6.jpeg"),
        subHeader: "Nasher miles, Gifts expo, Delhi",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Nasher_miles_Gifts_expo_Delhi/n7.jpeg"),
        subHeader: "Nasher miles, Gifts expo, Delhi",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Nasher_miles_Gifts_expo_Delhi/n8.jpeg"),
        subHeader: "Nasher miles, Gifts expo, Delhi",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Nasher_miles_Gifts_expo_Delhi/n9.jpeg"),
        subHeader: "Nasher miles, Gifts expo, Delhi",
      },
    ]
  },
  
 
  {
    id: 21,
    imgUrl: require("../../../src/images/portfolio/Vimal_Intertrade/Stona_Expo_Bangalore/s3.jpeg"),
    title: "Vimal Intertrade",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Vimal_Intertrade/Stona_Expo_Bangalore/s1.jpeg"),
        subHeader: "Stona Expo, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Vimal_Intertrade/Stona_Expo_Bangalore/s2.jpeg"),
        subHeader: "Stona Expo, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Vimal_Intertrade/Stona_Expo_Bangalore/s3.jpeg"),
        subHeader: "Stona Expo, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Vimal_Intertrade/PU_tech_Noida/p1.jpeg"),
        subHeader: "PU tech, Noida",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Vimal_Intertrade/PU_tech_Noida/p2.jpeg"),
        subHeader: "PU tech, Noida",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Vimal_Intertrade/PU_tech_Noida/p3.jpeg"),
        subHeader: "PU tech, Noida",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Vimal_Intertrade/PU_tech_Noida/p4.jpeg"),
        subHeader: "PU tech, Noida",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Vimal_Intertrade/PU_tech_Noida/p5.jpeg"),
        subHeader: "PU tech, Noida",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Vimal_Intertrade/PU_tech_Noida/p6.jpeg"),
        subHeader: "PU tech, Noida",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Vimal_Intertrade/PU_tech_Noida/p7.jpeg"),
        subHeader: "PU tech, Noida",
      },
    ]
  },
  {
    id: 22,
    imgUrl: require("../../../src/images/portfolio/VK_jewels/SJS_Bangalore_September_2022/v1.jpeg"),
    title: "VK jewels",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/VK_jewels/SJS_Bangalore_September_2022/v1.jpeg"),
        subHeader: "SJS, Bangalore September 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/VK_jewels/SJS_Bangalore_September_2022/v2.jpeg"),
        subHeader: "SJS, Bangalore September 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/VK_jewels/SJS_Bangalore_September_2022/v3.jpeg"),
        subHeader: "SJS, Bangalore September 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/VK_jewels/SJS_Bangalore_March_2022/v1.jpeg"),
        subHeader: "SJS Bangalore March 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/VK_jewels/SJS_Bangalore_March_2022/v2.jpeg"),
        subHeader: "SJS Bangalore March 2022",
      },
    ]
  },
  {
    id: 23,
    imgUrl: require("../../../src/images/portfolio/Sri_Mondal_Jewels_GJIIF_Chennai_2022/s1.jpeg"),
    title: "Sri Mondal Jewels, GJIIF, Chennai 2022",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Sri_Mondal_Jewels_GJIIF_Chennai_2022/s1.jpeg"),
        subHeader: "Sri Mondal Jewels, GJIIF, Chennai 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Sri_Mondal_Jewels_GJIIF_Chennai_2022/s2.jpeg"),
        subHeader: "Sri Mondal Jewels, GJIIF, Chennai 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Sri_Mondal_Jewels_GJIIF_Chennai_2022/s3.jpeg"),
        subHeader: "Sri Mondal Jewels, GJIIF, Chennai 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Sri_Mondal_Jewels_GJIIF_Chennai_2022/s4.jpeg"),
        subHeader: "Sri Mondal Jewels, GJIIF, Chennai 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Sri_Mondal_Jewels_GJIIF_Chennai_2022/s5.jpeg"),
        subHeader: "Sri Mondal Jewels, GJIIF, Chennai 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Sri_Mondal_Jewels_GJIIF_Chennai_2022/s6.jpeg"),
        subHeader: "Sri Mondal Jewels, GJIIF, Chennai 2022",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Sri_Mondal_Jewels_GJIIF_Chennai_2022/s7.jpeg"),
        subHeader: "Sri Mondal Jewels, GJIIF, Chennai 2022",
      },
    ]
  },
  {
    id: 24,
    imgUrl: require("../../../src/images/portfolio/Thirumala_South_Jewellery_Show_Bengaluru/t1.jpeg"),
    title: "Thirumala, South Jewellery Show Bengaluru",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Thirumala_South_Jewellery_Show_Bengaluru/t1.jpeg"),
        subHeader: "Thirumala, South Jewellery Show Bengaluru",
      },
    ]
  },
  {
    id: 25,
    imgUrl: require("../../../src/images/portfolio/Vajra_sjs_Bangalore/v1.jpeg"),
    title: "Vajra, sjs, Bangalore",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Vajra_sjs_Bangalore/v1.jpeg"),
        subHeader: "Vajra, sjs, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Vajra_sjs_Bangalore/v2.jpeg"),
        subHeader: "Vajra, sjs, Bangalore",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Vajra_sjs_Bangalore/v3.jpeg"),
        subHeader: "Vajra, sjs, Bangalore",
      },
    ]
  },
  {
    id: 26,
    imgUrl: require("../../../src/images/portfolio/Venus_Gartex_Mumbai/v1.jpeg"),
    title: "Venus, Gartex, Mumbai",
    imageGalery: [
      {
        imgUrl: require("../../../src/images/portfolio/Venus_Gartex_Mumbai/v1.jpeg"),
        subHeader: "Venus, Gartex, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Venus_Gartex_Mumbai/v2.jpeg"),
        subHeader: "Venus, Gartex, Mumbai",
      },
      {
        imgUrl: require("../../../src/images/portfolio/Venus_Gartex_Mumbai/v3.jpeg"),
        subHeader: "Venus, Gartex, Mumbai",
      },
    ]
  },
];
