import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import Subheader from "../../layout/Subheader";
import { Container } from "react-bootstrap";
import Cities from "../home/Cities";

export default function AboutInfo() {
    return (
      <>   
      <Container>
    
        <h4 className="sectiontitle">ABOUT HONEYCOMB</h4>
        <h6>WE PROVIDE HIGH QUALITY & COST EFFECTIVE SERVICES</h6>
        <p>
        Do you want to give your brand new identity? Are you ready to exhibit your brand to the masses? Do you want to design a stall for your brand who capture 
        maximum attention and get more business? Then think no further than Honeycombexpo Pvt Ltd. Right from sketching a concept all through the finishing, 
        we are your one-stop solution for all your exhibiting needs. 
        </p>
        <p>
        You can be assured that we, like your exhibition stall constructor, will capture and communicate the essence of your brand thoroughly to the audience. We 
        provide you with the most innovative stall designs and ensure that you have a smooth and cost-effective process. Our name is a known identity among 
        many top industries for whom we have designed, manufactured and installed exhibition stalls. Honeycombexpo Pvt Ltd delivers highly professional 
        designs and fabrication services to meet your every need and expectation. We are the preferred choice in the industry for the following reasons.<br/>
        <ul>
            <li>Vast Experience Of The Industry & a Strong & Qualified Creative Team</li>
            <li>The Best-In-Class Craftsmen</li>
            <li>State-Of-The-Art Facilities & Infrastructure</li>
            <li>Smooth & Hassle-Free Experience</li>
        </ul>
        </p>
        <p>
        We go beyond limits to fulfill our client's expectations and deliver top-notch work. Our exhibition approach is out of the box and we always deliver more 
than what is expected. Trust is the name of the game for us. We give your brand an experience that has never been witnessed before.<br/>
We offer construction & warehousing in Pan India basis: Mumbai, Pune, Surat, Jaipur, Delhi / NCR, Bangalore, Chennai, Hyderabad, Coimbatore, etc.
        </p>
       
        </Container> 
      </>
    )
    } 