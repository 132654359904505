import React from "react";

function CommonLabel({
  label,
  isRequired
}: {
  label: any;
  isRequired?: boolean;
}) {
  return (
    <>
      {label && (
        <span className="mt-3" style={{ fontWeight: "600", fontSize:"12px" }}>
          {label}
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </span>
      )}
    </>
  );
}

export default CommonLabel;
