import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Container, Row,Col,Image } from "react-bootstrap";

export default function WareHousing() {
    return (
      <>
      <div className="dark" style={{color:"#fff"}}>
      <Container>
      <h4>5 REASONS YOU SHOULD STORE YOUR EXHIBITION EQUIPMENT WITH Us</h4>
      <p>Being a business owner you will be knowing the benefits of attending your industry exhibitions. 
        A well designed exhibition stall can expose your business to potential clients, this will take your business 
        profile to next level within your industry.<br/>
        At Honeycomb Exhibits we can design and fabricate your stall for you, can also solve the problem of 
        storing it when it’s not in use.</p>
        <ul>
            <li>Prevent damage to your expensive stand and equipment</li>
            <li>You’ll always be ready for the next exhibition</li>
            <li>All the space you need, when you need it</li>
            <li>Safe and secure</li>
            <li>More than just exhibition space</li>
        </ul>
        </Container>
        </div>
      </>
    )
}