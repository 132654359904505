import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Container, Row, Col, Image } from "react-bootstrap";
//import EmptyRow from "../EmptyRow";
//import Footer from "../../layout/Footer";
import Subheader from "../../layout/Subheader";
//import Clients from "../home/Clients";
import c1 from "../../images/clients/1.png";
import c2 from "../../images/clients/2.png";
import c3 from "../../images/clients/3.png";
import c4 from "../../images/clients/4.png";
//import c5 from "../../images/clients/5.png";
import c6 from "../../images/clients/6.png";
// import c7 from "../../images/clients/7.png";
// import c8 from "../../images/clients/8.png";
import c9 from "../../images/clients/9.png";
import c10 from "../../images/clients/10.png";
import c11 from "../../images/clients/11.png";
import c12 from "../../images/clients/12.png";
import c13 from "../../images/clients/13.png";
import c14 from "../../images/clients/14.png";
import c15 from "../../images/clients/15.png";
import c16 from "../../images/clients/16.png";
import c17 from "../../images/clients/17.png";
import c18 from "../../images/clients/18.png";
import c19 from "../../images/clients/19.png";
import c20 from "../../images/clients/20.png";
//import c21 from "../../images/clients/21.png";
import c22 from "../../images/clients/22.png";
import c23 from "../../images/clients/23.png";
// import c24 from "../../images/clients/24.png";
// import c25 from "../../images/clients/25.png";
import c26 from "../../images/clients/26.png";
import c27 from "../../images/clients/27.png";
// import c28 from "../../images/clients/28.png";
// import c29 from "../../images/clients/29.png";
import c30 from "../../images/clients/30.png";
import c31 from "../../images/clients/31.png";
import c32 from "../../images/clients/32.png";
import c33 from "../../images/clients/33.png";
import c34 from "../../images/clients/34.png";
import c35 from "../../images/clients/35.png";
import c36 from "../../images/clients/36.png";
import c37 from "../../images/clients/37.png";
import c38 from "../../images/clients/38.png";
import c39 from "../../images/clients/39.png";
import c40 from "../../images/clients/40.png";
import c41 from "../../images/clients/41.png";
import c42 from "../../images/clients/42.png";
import c43 from "../../images/clients/43.png";
import c44 from "../../images/clients/44.png";
import c45 from "../../images/clients/45.png";
import c46 from "../../images/clients/46.png";
// import c47 from "../../images/clients/47.png";
// import c48 from "../../images/clients/48.png";
import c49 from "../../images/clients/49.png";
import c50 from "../../images/clients/50.png";
// import c51 from "../../images/clients/51.png";
// import c52 from "../../images/clients/52.png";
import c53 from "../../images/clients/53.png";
import c54 from "../../images/clients/54.png";
export default function OurClients() {
  const clientList= [
    {id:1,imgPath:c1},
    {id:2,imgPath:c2},
    {id:3,imgPath:c3},
    {id:4,imgPath:c4},
    // {id:5,imgPath:c5},
    {id:6,imgPath:c6},
    // {id:7,imgPath:c7},
    // {id:8,imgPath:c8},
    {id:9,imgPath:c9},
    {id:10,imgPath:c10},
    {id:11,imgPath:c11},
    {id:12,imgPath:c12},
    {id:13,imgPath:c13},
    {id:14,imgPath:c14},
    {id:15,imgPath:c15},
    {id:16,imgPath:c16},
    {id:17,imgPath:c17},
    {id:18,imgPath:c18},
    {id:19,imgPath:c19},
    {id:20,imgPath:c20},
    // {id:21,imgPath:c21},
    {id:22,imgPath:c22},
    {id:23,imgPath:c23},
    // {id:24,imgPath:c24},
    // {id:25,imgPath:c25},
    {id:26,imgPath:c26},
    {id:27,imgPath:c27},
    // {id:28,imgPath:c28},
    // {id:29,imgPath:c29},
    {id:30,imgPath:c30},
    {id:31,imgPath:c31},
    {id:32,imgPath:c32},
    {id:33,imgPath:c33},
    {id:34,imgPath:c34},
    {id:35,imgPath:c35},
    {id:36,imgPath:c36},
    {id:37,imgPath:c37},
    {id:38,imgPath:c38},
    {id:39,imgPath:c39},
    {id:40,imgPath:c40},
    {id:41,imgPath:c41},
    {id:42,imgPath:c42},
    {id:43,imgPath:c43},
    {id:44,imgPath:c44},
    {id:45,imgPath:c45},
    {id:46,imgPath:c46},
    // {id:47,imgPath:c47},
    // {id:48,imgPath:c48},
    {id:49,imgPath:c49},
    {id:50,imgPath:c50},
    // {id:51,imgPath:c51},
    // {id:52,imgPath:c52},
    {id:53,imgPath:c53},
    {id:54,imgPath:c54},
  ]
  return (
    <>
      <Subheader />
      <h4 className="title mt-3 pt-3 mb-5">HONEYCOMB VALUABLE CLIENTS</h4>
      <Container>
      <Row className="mt-2">
        {
          clientList?.map((item)=>{
              return(
                <Col lg={2} md={2} sm={2} xs={6} className="">
                   <a href='/Gallery'><Image src={item?.imgPath} className="client" style={{width:"100%"}} /></a>
               </Col>
              )
          })
        }
      </Row>
{/* 
      <Row className="mt-2 ">
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c7} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c8} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c9} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c10} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c11} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c12} className="client" />
        </Col>
      </Row>

      <Row>
      
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c13} className="client"  />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c14} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c15} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c16} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c17} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c18} className="client" />
        </Col>
      </Row>


      <Row className="mt-2 pb-5">
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c19} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c20} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c21} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c22} className="client" />
        </Col>  
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c23} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c24} className="client" />
        </Col>   
      </Row>

      <Row className="mt-2 pb-5">
        
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c25} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c26} className="client" />
        </Col>   
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c27} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c28} className="client" />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c29} className="client" />
        </Col>   
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c30} className="client" />
        </Col>   
      </Row>
      <Row className="mt-2 pb-5">
        
        <Col lg={2} md={2} sm={2} xs={12}>
          <Image src={c31} className="client" />
        </Col>
          
      </Row> */}
      </Container>
      
      {/* <Footer /> */}
    </>
  );
}
