import React from "react";
import {
  Row,
  Col,
  Image,
  Button,
  FloatingLabel,
  Form,
  Placeholder,
} from "react-bootstrap";
import CommonLabel from "./CommonLabel";

const InputField = ({
  type,
  as,
  rows,
  label,
  value,
  onchange,
  placeholder,
  isRequired = false,
  isDisabled = false,
  regex,
  errMessage = "This field is Required."
}: {
  type?: any;
  label?: any;
  as?: any;
  value?: any;
  rows?: any;
  onchange?: any;
  placeholder?: any;
  isRequired?: boolean;
  regex?: any;
  errMessage?: any;
  isDisabled?: boolean;
}) => {
  if (isRequired) {
  }

  return (
    <>
      <CommonLabel label={label} isRequired={isRequired} />
      <Form.Group>
        <Form.Control
          type={type}
          as={as}
          rows={rows}
          value={value}
          placeholder={placeholder ? placeholder : ""}
          onChange={(e) => {
            onchange(e.target.value);
          }}
          required={isRequired}
          disabled={isDisabled}
          pattern={regex}
        />
        <Form.Control.Feedback type="invalid">
          {errMessage}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};
export default InputField;

function setTitle(value: string): void {
  throw new Error("Function not implemented.");
}
