import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import Slider from "../../layout/Slider";
import Abouth from "./Abouth";
import Clients from "./Clients";
//import Gallery from "./Gallery";
import App from "./Gallery1";
//import Portfolio from "./Portfolio";
import Workflow from "./Workflow";
import Cities from "./Cities";
import Services from "./Services";
// import Contact from "../contact/Contact";
// import AboutUs from "../about/AboutUs";
// import TopHeader from "../../layout/TopHeader";
// import Footer from "../../layout/Footer";



export default function Home() {
    return (
      <> 
    {/* <TopHeader/>     */}
    <Slider />
     <Abouth />
     <Workflow />
     <Services />
     <Clients />
     <Cities />    
     <App />    
     {/* <Portfolio />  */}
     {/* <Footer/> */}
      </>
    )
    } 