import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Col, Container, Row } from "react-bootstrap";

export default function Facts() {
    return (
      <> 
     <section className="dark">   
    <Container>    
        <h4 className="title ">COMPANY FACTS</h4>
        <div className="text-white">
        <p>Our Jpurney so far...</p>
        <Row>
        <Col xl={2} lg={2} md={2} sm={6}> 
         <span className="facts animate__animated animate__bounceInDown ">35+</span> <br/>Happy Clients
        </Col>
        <Col xl={2} lg={2} md={2} sm={6}> 
        <span className="facts animate__animated animate__fadeInDown ">90%</span><br/> Repeat Business
        </Col>
        <Col xl={2} lg={2} md={2} sm={6}> 
        <span className="facts animate__animated animate__fadeInDown ">10+</span> <br/> Cities(Offices)
        </Col>
        <Col xl={2} lg={2} md={2} sm={6}> 
        <span className="facts animate__animated animate__fadeInDown ">30+</span> <br/> Teams Members
        </Col>
        <Col xl={2} lg={2} md={2} sm={6}> 
        <span className="facts animate__animated animate__fadeInDown">12+</span><br/> Years of Collective Experience
        </Col>
        <Col xl={2} lg={2} md={2} sm={6}> 
        <span className="facts animate__animated animate__fadeInDown ">100+</span> <br/> Projects Completed
        </Col>
        </Row>
        </div>
    </Container>
    </section>
      </>
    )
    } 