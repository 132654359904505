import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
//import { Container, Row,Col,Image } from "react-bootstrap";
import CoreServices from "./CoreServices";
import Subheader from "../../layout/Subheader";
import StallTypes from "./StallTypes";
import WareHousing from "./WareHousing";
import EmptyRow from "../EmptyRow";
//import Footer from "../../layout/Footer";

export default function OurServices() {
    return (
      <>
      <Subheader/> 
      <h4 className="title mt-3 pt-3 mb-5">HONEYCOMB SERVICES</h4>
      <CoreServices/>
      <StallTypes/>
      <EmptyRow/>
      <WareHousing/>
      {/* <Footer/> */}
      </>
    )
}