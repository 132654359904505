import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css";
export default function imageSlider(data) {
 console.log(data, "9999999999999999999999")
  return (
    <Carousel autoPlay  className="imageSlider">
      {data?.data?.map((item) => {
        return (
          <>
          <img key={item?.imgUrl} alt="" className="galleryImg" src={item?.imgUrl} />      
          <h5 style={{color:'#ffc515', textTransform: 'capitalize'}}>{item?.subHeader}</h5>    
          </>
          )
        ;
      })}
    </Carousel>
  );
}
