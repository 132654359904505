import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Container, Row,Col,Image } from "react-bootstrap";
import w1 from "../../images/workflow/1.png";
import w2 from "../../images/workflow/2.png";
import w3 from "../../images/workflow/3.png";
import w4 from "../../images/workflow/4.png";
import w5 from "../../images/workflow/5.png";
import w6 from "../../images/workflow/6.png";
import w7 from "../../images/workflow/7.png";
import w8 from "../../images/workflow/8.png";
import w9 from "../../images/workflow/9.png";
import w10 from "../../images/workflow/10.png";
import w11 from "../../images/workflow/11.png";


export default function Workflow() {
    return (
      <>
      <section className="dark"> 
      <h4 className="title mb-5 animate__animated animate__flipInX"> OUR WORKFLOW</h4>
      {/* <Row >
     
      <Col lg={2} md={2} xs={2}><p className="twhite">REQUIREMENT</p></Col>
      <Col lg={2} md={2} xs={2}><p className="twhite">COMMERCIAL</p></Col>
      <Col lg={2} md={2} xs={2}><p className="twhite">TRANSPORTATION</p></Col>
      <Col lg={2} md={2} xs={2}><p className="twhite">DISMANTLING</p></Col>
      <Col lg={2} md={2} xs={2}><p className="twhite">RECYCLED</p></Col>
     
      </Row> */}
      <Row className="pb-3 animate__animated animate__zoomIn ">
      <div className="equelDiv"><Image src={w1} className="wicon header drop-in"/><span className="twhite">LEAD</span></div>
      <div className="equelDiv"><Image src={w2} className="wicon header drop-in"/><span className="twhite">REQUIREMENT</span></div>
      <div className="equelDiv"><Image src={w3} className="wicon header drop-in"/><span className="twhite">CONCEPT</span></div>
      <div className="equelDiv"><Image src={w4} className="wicon header drop-in"/><span className="twhite">COMMERCIAL</span></div>
      <div className="equelDiv"><Image src={w5} className="wicon header drop-in"/><span className="twhite">PRODUCTION</span></div>
      <div className="equelDiv"><Image src={w6} className="wicon header drop-in"/><span className="twhite">TRANSPORTATION</span></div>
      <div className="equelDiv"><Image src={w7} className="wicon header drop-in"/><span className="twhite">DISMANTLING</span></div>
      <div className="equelDiv"><Image src={w8} className="wicon header drop-in"/><span className="twhite">SETUP</span></div>
      <div className="equelDiv"><Image src={w9} className="wicon header drop-in"/><span className="twhite">WAREHOUSING</span></div>
      <div className="equelDiv"><Image src={w10} className="wicon header drop-in"/><span className="twhite">RECYCLED</span></div>
      <div className="equelDiv"><Image src={w11} className="wicon header drop-in"/><span className="twhite">REPEAT</span></div>
      </Row>
      {/* <Row className="pb-3">
      <Col lg={1} md={1} xs={1}></Col>  
      <Col lg={1} md={1} xs={1}><p className="twhite">LEAD</p></Col>
      <Col lg={2} md={2} xs={2}><p className="twhite">CONCEPT</p></Col>
      <Col lg={2} md={2} xs={2}><p className="twhite">PRODUCTION</p></Col>
      <Col lg={2} md={2} xs={2}><p className="twhite">SETUP</p></Col>
      <Col lg={2} md={2} xs={2}><p className="twhite">WAREHOUSING</p></Col>
      <Col lg={2} md={2} xs={2}><p className="twhite">REPEAT</p></Col>
      </Row> */}
      {/* <Row className="mt-4 pb-4 center">
        <Col lg={3} md={3} sm={3}></Col>
        <Col lg={2} md={2} sm={2} xs={4}><Image src={evaluate} className="homeicons"/><br/>EVALUATE</Col>
        <Col lg={2} md={2} sm={2} xs={4}><Image src={planning} className="homeicons"/><br/>PLANNING</Col>
        <Col lg={2} md={2} sm={2} xs={4}><Image src={execute} className="homeicons"/><br/>EXECUTE</Col>
        <Col lg={3} md={3} sm={3}></Col>
      </Row> */}
      </section>
      </>
    )}