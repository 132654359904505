import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row, Image,} from "react-bootstrap";

export default function EmptyRow() {
    return (
      <> 
        <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
        &nbsp;
         </Col>   
        </Row>
      </>
    )
    } 