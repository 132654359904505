import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Container, Row,Col,Image } from "react-bootstrap";
import Stall1 from "../../images/services/oneside.png";
import Stall2 from "../../images/services/twoside.png";
import Stall3 from "../../images/services/threeside.png";
import Stall4 from "../../images/services/fourside.png";
import Stall5 from "../../images/services/mezzanine.png";
import EmptyRow from "../EmptyRow";

export default function StallTypes() {
    return (
      <>
      <div className="light">
      <Container>
      <h4 className="title mt-3 pt-3 mb-5">STALL TYPES WE CAN HELP YOU WITH</h4>
      <Row>
        <Col lg={4} md={4} sm={12} xs={12}>
           <Image src={Stall1} className="servicepic" />
        </Col>

        <Col lg={8} md={8} sm={12} xs={12}>
           <h4>ONE SIDE OPEN</h4>
           <p className="servicetitle">A one side open stall design is also known as a shell scheme design. We offer this shell 
                                       scheme design with custom made design as per your choice This system or designs can be 
                                       extended from 3m x 3m to 6m x 3m. We at Honeycomb can give you best and unique design as per 
                                       your stall size
           </p>           
        </Col>
        </Row>
         <EmptyRow/>
        <Row>
        <Col lg={4} md={4} sm={12} xs={12}>
           <Image src={Stall2} className="servicepic" />
        </Col>

        <Col lg={8} md={8} sm={12} xs={12}>
           <h4>TWO SIDE OPEN</h4>
           <p className="servicetitle">A two side open stall design is basically a corner or front back stall in a row, and get more 
                                       attention of visitors. Having two side open stall is a always be a good option to 
                                       showcase. Your products to visitor. We at Honeycomb can give you best and unique design as per 
                                       your stall size for two side open stall.
           </p>           
        </Col>
        </Row>
        <EmptyRow/>
        <Row>
        <Col lg={4} md={4} sm={12} xs={12}>
           <Image src={Stall3} className="servicepic" />
        </Col>

        <Col lg={8} md={8} sm={12} xs={12}>
           <h4>THREE SIDE OPEN</h4>
           <p className="servicetitle">A 3 side open stall design is 
                                       also known as a raw space, its 
                                       offers you the best possibilities to 
                                       design the stall. 
                                       We at Honeycomb can offer you 
                                       wide range of best and unique 
                                       design as per your stall size, for 
                                       three side open stall.
           </p>           
        </Col>
        </Row>
        <EmptyRow/>
        <Row>
        <Col lg={4} md={4} sm={12} xs={12}>
           <Image src={Stall4} className="servicepic" />
        </Col>

        <Col lg={8} md={8} sm={12} xs={12}>
           <h4>FOUR SIDE OPEN</h4>
           <p className="servicetitle">A two side open stall design is 
                                       basically a corner or front back 
                                       stall in a row, and get more 
                                       attention of visitors.
                                       Having two side open stall is a 
                                       always be a good option to 
                                       showcase. Your products to 
                                       visitor.
                                       We at Honeycomb can give you 
                                       best and unique design as per 
                                       your stall size for two side open 
                                       stall.
           </p>           
        </Col>
        </Row>
        <EmptyRow/>
        <Row>
        <Col lg={4} md={4} sm={12} xs={12}>
           <Image src={Stall5} className="servicepic" />
        </Col>

        <Col lg={8} md={8} sm={12} xs={12}>
           <h4>MEZZANINE STALL</h4>
           <p className="servicetitle">Exhibitor and exhibitions welcome the 
                                       most creative stalls and the agency of 
                                       the stall design. 
                                       Designing and production of the 
                                       Mezzanine stall is the hardest job in all 
                                       kind of stall designs, we at Honeycomb 
                                       has the speciality and understanding 
                                       need of exhibitor, our specialized team 
                                       is always ready to take any kind of 
                                       challenges when its come to designing 
                                       and production of the stall. Mezzanine 
                                       stall benefits a lot to exhibitor from point 
                                       of branding looks special & creative 
                                       from others.
                                       We at Honeycomb can give you best 
                                       and unique design as per your stall size 
                                       for mezzanine stall
           </p>           
        </Col>
        </Row>
      </Container>
      </div>
      </>
    )
}