import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {Image} from "react-bootstrap";
import SubImg from "../images/subheader.jpg";
//import TopHeader from "./TopHeader";
//import Footer from "./Footer";


    export default function Subheader() {
    return (
      <>  
     {/* <TopHeader/>   */}
     <Image src={SubImg} style={{width:"100%"}}/>
      </>
    )
    } 