import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Container, Row, Col, Image, Popover, OverlayTrigger, Button } from "react-bootstrap";
import Mumbai from "../../images/cities/mumbai.png";
import Pune from "../../images/cities/pune.png";
import Delhi from "../../images/cities/delhi.png";
import Hyderabad from "../../images/cities/hyderabad.png";
import Jaipur from "../../images/cities/jaipur.png";
import Banglore from "../../images/cities/banglore.png";
import Surat from "../../images/cities/surat.png";
import Ahmedabad from "../../images/cities/ahmedabad.png";
import Goa from "../../images/cities/goa.png";
import Kolkata from "../../images/cities/kolkata.png";
import Nashik from "../../images/cities/nashik.jpg";
import Chennai from "../../images/cities/chennai.png";






export default function Cities() {
    return (
      <>
      <div className="light pb-5">
        <Container>
            <h4 className="title mt-3 pt-3 mb-3 ">WE OPERATE IN</h4>
            <Row>
            <Col xl={2} lg={2} md={2} sm={6}>  </Col>
                <Col xl={2} lg={2} md={2} sm={6}>    
                <div className="containerimg">         
                <Image src={Mumbai} className="city"/>
                    <div className="middle">
                     <div className="text">Mumbai</div>
                     </div>
                 </div>    
                </Col>
                <Col xl={2} lg={2} md={2} sm={6}>
                <div className="containerimg">         
                <Image src={Jaipur} className="city"/>
                    <div className="middle">
                     <div className="text"> Jaipur</div>
                     </div>
                 </div>   
                </Col>              
                <Col xl={2} lg={2} md={2} sm={6}>
                <div className="containerimg">         
                <Image src={Delhi} className="city"/>
                    <div className="middle">
                     <div className="text">Delhi</div>
                     </div>
                 </div>    
                </Col>
                <Col xl={2} lg={2} md={2} sm={6}>
                <div className="containerimg">         
                <Image src={Hyderabad} className="city"/>
                    <div className="middle">
                     <div className="text">Hyderabad</div>
                     </div>
                 </div>    
                </Col>
                <Col xl={2} lg={2} md={2} sm={6}>  </Col>
                </Row>


                <Row className="mt-3">
                <Col xl={2} lg={2} md={2} sm={6}></Col>
                <Col xl={2} lg={2} md={2} sm={6}>
                <div className="containerimg">         
                <Image src={Pune} className="city"/>
                    <div className="middle">
                     <div className="text">Pune</div>
                     </div>
                 </div>   
                </Col>
                <Col xl={2} lg={2} md={2} sm={6}>
                <div className="containerimg">         
                <Image src={Banglore} className="city"/>
                    <div className="middle">
                     <div className="text"> Bangalore</div>
                     </div>
                 </div>   
                </Col>
                <Col xl={2} lg={2} md={2} sm={6}>
                <div className="containerimg">         
                <Image src={Surat} className="city"/>
                    <div className="middle">
                     <div className="text"> Surat</div>
                     </div>
                 </div>   
                </Col>  
                <Col xl={2} lg={2} md={2} sm={6}>
                <div className="containerimg">         
                <Image src={Ahmedabad} className="city"/>
                    <div className="middle">
                     <div className="text"> Ahmedabad</div>
                     </div>
                 </div>   
                </Col>  
                <Col xl={2} lg={2} md={2} sm={6}>  </Col>         
            </Row>

            <Row className="mt-3">
                <Col xl={2} lg={2} md={2} sm={6}></Col>
                <Col xl={2} lg={2} md={2} sm={6}>
                <div className="containerimg">         
                <Image src={Goa} className="city"/>
                    <div className="middle">
                     <div className="text">Goa</div>
                     </div>
                 </div>   
                </Col>
                <Col xl={2} lg={2} md={2} sm={6}>
                <div className="containerimg">         
                <Image src={Kolkata} className="city"/>
                    <div className="middle">
                     <div className="text"> Kolkata</div>
                     </div>
                 </div>   
                </Col>
                <Col xl={2} lg={2} md={2} sm={6}>
                <div className="containerimg">         
                <Image src={Chennai} className="city"/>
                    <div className="middle">
                     <div className="text"> Chennai</div>
                     </div>
                 </div>   
                </Col>  
                <Col xl={2} lg={2} md={2} sm={6}>
                <div className="containerimg">         
                <Image src={Nashik} className="city"/>
                    <div className="middle">
                     <div className="text"> Nashik</div>
                     </div>
                 </div>   
                </Col>  
                <Col xl={2} lg={2} md={2} sm={6}>  </Col>         
            </Row>
            </Container>
            </div>
      </>
    )
    } 